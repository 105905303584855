import React from "react"

import SchoolHeaderSection from "../../sections/schools/SchoolHeaderSection"
import SchoolItemSubjectsSection from "../../sections/schools/SchoolItemSubjectsSection"
import SchoolFooterSection from "../../sections/schools/SchoolFooterSection"
import Layout from "../../components/Layout"
import SideNavApplyDocsSection from "../../sections/SideNavApplyDocsSection"

const SchoolPageSubjects = props => {
  return (
    <Layout
      wrapperClass={"school-item subjects-item"}
      pageTitle={`${props.pageContext.currentSchool.name} | Smart Admissions Service`}
      isSecondLayout={true}
    >
      <SchoolHeaderSection
        isMainSchoolTab={false}
        state5="active"
        school={props.pageContext.currentSchool}
        currentSchoolScholarships={props.pageContext.currentSchoolScholarships}
        schoolSectionHeader="shrinked"
        schoolSectionHeaderLogo="hide"
      />
      <SchoolItemSubjectsSection
        certs={props.pageContext.certs}
        subjects={props.pageContext.subjects}
        isSchoolPage={true}
      />
      <SchoolFooterSection
        school={props.pageContext.currentSchool}
        schools={props.pageContext.schools}
      />
      <SideNavApplyDocsSection school={props.pageContext.currentSchool} />
    </Layout>
  )
}

export default SchoolPageSubjects
